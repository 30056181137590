import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { ClickOutsideModule } from "ng-click-outside";
import { UIModule } from "../shared/ui/ui.module";
import { LayoutComponent } from "./layout.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { RouterModule, Routes } from "@angular/router";
import { VendorTopbarComponent } from "./vendor-topbar/vendor-topbar.component";
import { AdminTopbarComponent } from "./admin-topbar/admin-topbar.component";
import { ResidentialVendorTopbarComponent } from "./residential-vendor-topbar/residential-vendor-topbar.component";
import { ResidentialVendorSidebarComponent } from "./residential-vendor-sidebar/residential-vendor-sidebar.component";
import { ResidentialAdminSidebarComponent } from "./residential-admin-sidebar/residential-admin-sidebar.component";

@NgModule({
  declarations: [
    LayoutComponent,
    ResidentialAdminSidebarComponent,
    SidebarComponent,
    TopbarComponent,
    VendorTopbarComponent,
    AdminTopbarComponent,
    ResidentialVendorTopbarComponent,
    ResidentialVendorSidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
  ],
  exports: [
    SidebarComponent,
    TopbarComponent,
    VendorTopbarComponent,
    AdminTopbarComponent,
    ResidentialVendorSidebarComponent,
    ResidentialVendorTopbarComponent,
    ResidentialAdminSidebarComponent,
  ],
})
export class LayoutsModule {}
