import { Component, OnInit } from "@angular/core";
declare var $: any;

@Component({
  selector: "app-residential-admin-sidebar",
  templateUrl: "./residential-admin-sidebar.component.html",
  styleUrls: ["./residential-admin-sidebar.component.scss"],
})
export class ResidentialAdminSidebarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  /* This function will close sidebar */

  close() {
    $("body").removeClass("sidebar-enable");
  }
}
