import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  close(){
    $("body").removeClass("sidebar-enable");
    localStorage.removeItem('visitedEditSpotCom');
  }
}
