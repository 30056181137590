import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { VendorSidebarComponent } from './vendor-sidebar/vendor-sidebar.component';
import { UIModule } from './ui/ui.module';
import { AdminSidebarComponent } from '../shared/admin-sidebar/admin-sidebar.component';

@NgModule({
  declarations: [
    VendorSidebarComponent,
    AdminSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule, 
    UIModule
  ],
  exports:[
    VendorSidebarComponent,
    AdminSidebarComponent
  ]
})
export class SharedModule { }
