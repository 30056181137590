import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CommonServiceService } from "src/app/service/common-service.service";
import { ConstantsService } from "src/app/service/constant-service";

@Component({
  selector: "app-residential-add-spots-admin",
  templateUrl: "./residential-add-spots-admin.component.html",
  styleUrls: ["./residential-add-spots-admin.component.scss"],
})
export class ResidentialAddSpotsAdminANPRComponent implements OnInit {
  addFrom: any;
  space: any;

  constructor(
    public apiservice: CommonServiceService,
    public constantsService: ConstantsService,
    public FormBuilder: FormBuilder,
    public toast: ToastrService
  ) {
    this.addFrom = this.FormBuilder.group({
      area: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
      landmark: new FormControl("", [Validators.required]),
      price: new FormControl("", [Validators.required]),

      city: new FormControl("", [Validators.required]),
      state: new FormControl("", [Validators.required]),
      spaceproviderid: new FormControl("", [Validators.required]),
      parkingspotname: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {}

  /* This function will add new parking manager */

  booking() {
    var data = {
      state: this.addFrom.value["state"],
      city: this.addFrom.value["city"],
      country: this.addFrom.value["country"],
      area: this.addFrom.value["area"],
      landmark: this.addFrom.value["landmark"],
      address: this.addFrom.value["address"],
      pricing_category: this.addFrom.value["price"],
      parking_spot_name: this.addFrom.value["parkingspotname"],
      spaceProviderId: this.addFrom.value["spaceproviderid"],
      userVehicleId: this.addFrom.value["uservehicleid"],
    };

    this.apiservice
      .postRequest(this.constantsService.PARKINGSPOTADMIN, data)
      .then((res) => {
        this.toast.success("Added Parking Manager Successfully");
      })
      .catch((err) => {});
  }

  parkingSpot() {
    this.apiservice
      .getRequest(this.constantsService.PARKINGSPACEADMIN, "")
      .then((res: any) => {
        this.space = res["data"];
      })
      .catch((err: any) => {});
  }
}
