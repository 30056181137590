import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';


const routes: Routes = [

  { path: '', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'layout', component: LayoutComponent, loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule), canActivate: [AuthGuard] },
  { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },

  // Admin start
  { path: 'admin', loadChildren: () => import('./parkit_admin/parking-manager/parking-manager.module').then(m => m.ParkingManagerModule) },
  { path: 'admin', loadChildren: () => import('./parkit_admin/parking-spots-admin/parking-spots-admin.module').then(m => m.ParkingSpotsAdminModule) },
  { path: 'admin', loadChildren: () => import('./parkit_admin/parking-logs/parking-logs.module').then(m => m.ParkingLogsModule) },
  { path: 'admin', loadChildren: () => import('./parkit_admin/booking-admin/booking-admin.module').then(m => m.BookingAdminModule) },
  { path: 'admin', loadChildren: () => import('./parkit_admin/space-provider/space-provider.module').then(m => m.SpaceProviderModule) },
  { path: 'admin', loadChildren: () => import('./parkit_admin/pricing/pricing.module').then(m => m.PricingModule) },
  { path: 'admin', loadChildren: () => import('./parkit_admin/company/company.module').then(m => m.CompanyModule) },
  { path: 'admin', loadChildren: () => import('./parkit_admin/users/users.module').then(u => u.UsersModule) },
  { path: 'admin', loadChildren: () => import('./parkit_admin/user-vehicles/user-vehicles.module').then(uv => uv.UserVehiclesModule) },

  // Vendor start
  { path: 'vendor', loadChildren: () => import('./parkit_vendor/booking-vendor/booking-vendor.module').then(m => m.BookingVendorModule) },
  { path: 'vendor', loadChildren: () => import('./parkit_vendor/parking-spots-vendor/parking-spots-vendor.module').then(m => m.ParkingSpotsVendorModule) },
  { path: 'vendor', loadChildren: () => import('./parkit_vendor/parking-logs/parking-logs.module').then(m => m.ParkingLogsModule) },
  { path: 'vendor', loadChildren: () => import('./parkit_vendor/parking-manager/parking-manager.module').then(m => m.ParkingManagerModule) },
  { path: 'vendor', loadChildren: () => import('./parkit_vendor/company/company.module').then(m => m.CompanyModule) },

  //Admin Residential
  { path: 'residential-admin-parking-manager', loadChildren: () => import('./residential_admin/parking-manager/parking-manager.module').then(m => m.ParkingManagerModule) },
  { path: 'residential-admin-parking-spot', loadChildren: () => import('./residential_admin/parking-spots-admin/parking-spots-admin.module').then(m => m.ParkingSpotsAdminModule) },
  { path: 'residential-admin-parking-log', loadChildren: () => import('./residential_admin/parking-logs/parking-logs.module').then(m => m.ParkingLogsModule) },
  { path: 'residential-admin-booking', loadChildren: () => import('./residential_admin/booking-admin/booking-admin.module').then(m => m.BookingAdminModule) },
  { path: 'residential-admin-space-provider', loadChildren: () => import('./residential_admin/space-provider/space-provider.module').then(m => m.SpaceProviderModule) },
  { path: 'residential-admin-registration', loadChildren: () => import('./residential_admin/newregistration/newregistration.module').then(m => m.NewregistrationModule) },

  //Vendor Residential
  { path: 'residential-vendor-booking', loadChildren: () => import('./residential_vendor/residential-booking-vendor/residential-booking-vendor.module').then(m => m.ResidentialBookingVendorModule) },
  { path: 'residential-vendor-parking-logs', loadChildren: () => import('./residential_vendor/residential-parking-logs/residential-parking-logs.module').then(m => m.ResidentialParkingLogsModule) },
  { path: 'residential-vendor-parking-manager', loadChildren: () => import('./residential_vendor/residential-parking-manager/residential-parking-manager.module').then(m => m.ResidentialParkingManagerModule) },
  { path: 'residential-vendor-parking-spots', loadChildren: () => import('./residential_vendor/residential-parking-spots-vendor/residential-parking-spots-vendor.module').then(m => m.ResidentialParkingSpotsVendorModule) },
  // { path: 'residential-vendor-gate', loadChildren: () => import('./residential_vendor/gate/gate.module').then(m => m.GateModule) },
  // { path: 'residential-vendor-rfid', loadChildren: () => import('./residential_vendor/rfid-passes/rfid-passes.module').then(m => m.RfidPassesModule) },
  //{ path: 'residential-vendor-purpose', loadChildren: () => import('./residential_vendor/purpose/purpose.module').then(m => m.PurposeModule) },
  { path: 'residential-vendor-car', loadChildren: () => import('./residential_vendor/resident-cars/resident-cars.module').then(m => m.ResidentCarsModule) },
  { path: 'residential-vendor-resident', loadChildren: () => import('./residential_vendor/residents/residents.module').then(m => m.ResidentsModule) },
  //{ path: 'residential-vendor-allocated-parking', loadChildren: () => import('./residential_vendor/allocated-parking/allocated-parking.module').then(m => m.AllocatedParkingModule) },
  { path: 'residential-vendor-registration', loadChildren: () => import('./residential_vendor/newregistration/newregistration.module').then(m => m.NewregistrationModule) },


  /* ******************** ===============  ANPR RESIDENT VENDOR  =============== ******************** */

  // Vendor ANPR Residential
  { path: 'anpr-residential-vendor-booking', loadChildren: () => import('./anpr_residential/residential-booking-vendor/residential-booking-vendor.module').then(m => m.ResidentialBookingVendorModule) },
  { path: 'anpr-residential-vendor-parking-logs', loadChildren: () => import('./anpr_residential/residential-parking-logs/residential-parking-logs.module').then(m => m.ResidentialParkingLogsModule) },
  { path: 'anpr-residential-vendor-parking-manager', loadChildren: () => import('./anpr_residential/residential-parking-manager/residential-parking-manager.module').then(m => m.ResidentialParkingManagerModule) },
  { path: 'anpr-residential-vendor-parking-spots', loadChildren: () => import('./anpr_residential/residential-parking-spots-vendor/residential-parking-spots-vendor.module').then(m => m.ResidentialParkingSpotsVendorModule) },
  // { path: 'anpr-residential-vendor-gate', loadChildren: () => import('./anpr_residential/gate/gate.module').then(m => m.GateModule) },
  // { path: 'anpr-residential-vendor-rfid', loadChildren: () => import('./anpr_residential/rfid-passes/rfid-passes.module').then(m => m.RfidPassesModule) },
  //{ path: 'anpr-residential-vendor-purpose', loadChildren: () => import('./anpr_residential/purpose/purpose.module').then(m => m.PurposeModule) },
  { path: 'anpr-residential-vendor-car', loadChildren: () => import('./anpr_residential/resident-cars/resident-cars.module').then(m => m.ResidentCarsModule) },
  { path: 'anpr-residential-vendor-resident', loadChildren: () => import('./anpr_residential/residents/residents.module').then(m => m.ResidentsModule) },
  //{ path: 'anpr-residential-vendor-allocated-parking', loadChildren: () => import('./anpr_residential/allocated-parking/allocated-parking.module').then(m => m.AllocatedParkingModule) },
  { path: 'anpr-residential-vendor-registration', loadChildren: () => import('./anpr_residential/newregistration/newregistration.module').then(m => m.NewregistrationModule) },


  /* ******************** ===============  RFID CORPORATE VENDOR  =============== ******************** */

  // Vendor RFID CORPORATE
  { path: 'rfid-corporate-residential-vendor-booking', loadChildren: () => import('./vendor_rfid_corporate/residential-booking-vendor/residential-booking-vendor.module').then(m => m.ResidentialBookingVendorModule) },
  { path: 'rfid-corporate-residential-vendor-parking-logs', loadChildren: () => import('./vendor_rfid_corporate/residential-parking-logs/residential-parking-logs.module').then(m => m.ResidentialParkingLogsModule) },
  { path: 'rfid-corporate-residential-vendor-parking-manager', loadChildren: () => import('./vendor_rfid_corporate/residential-parking-manager/residential-parking-manager.module').then(m => m.ResidentialParkingManagerModule) },
  { path: 'rfid-corporate-residential-vendor-parking-spots', loadChildren: () => import('./vendor_rfid_corporate/residential-parking-spots-vendor/residential-parking-spots-vendor.module').then(m => m.ResidentialParkingSpotsVendorModule) },
  // { path: 'rfid-corporate-residential-vendor-gate', loadChildren: () => import('./vendor_rfid_corporate/gate/gate.module').then(m => m.GateModule) },
  // { path: 'rfid-corporate-residential-vendor-rfid', loadChildren: () => import('./vendor_rfid_corporate/rfid-passes/rfid-passes.module').then(m => m.RfidPassesModule) },
  //{ path: 'rfid-corporate-residential-vendor-purpose', loadChildren: () => import('./vendor_rfid_corporate/purpose/purpose.module').then(m => m.PurposeModule) },
  { path: 'rfid-corporate-residential-vendor-car', loadChildren: () => import('./vendor_rfid_corporate/resident-cars/resident-cars.module').then(m => m.ResidentCarsModule) },
  { path: 'rfid-corporate-residential-vendor-resident', loadChildren: () => import('./vendor_rfid_corporate/residents/residents.module').then(m => m.ResidentsModule) },
  //{ path: 'rfid-corporate-residential-vendor-allocated-parking', loadChildren: () => import('./vendor_rfid_corporate/allocated-parking/allocated-parking.module').then(m => m.AllocatedParkingModule) },
  { path: 'rfid-corporate-residential-vendor-registration', loadChildren: () => import('./vendor_rfid_corporate/newregistration/newregistration.module').then(m => m.NewregistrationModule) },
  { path: 'rfid-corporate-residential-vendor-report', loadChildren: () => import('./vendor_rfid_corporate/report/report.module').then(r => r.ReportModule) },


  /* ******************** ===============  ANPR RESIDENT ADMIN  =============== ******************** */

  //Admin ANPR Residential
  { path: 'admin-anpr-residential-admin-parking-manager', loadChildren: () => import('./admin_anpr_resident/parking-manager/parking-manager.module').then(m => m.ParkingManagerModule) },
  { path: 'admin-anpr-residential-admin-parking-spot', loadChildren: () => import('./admin_anpr_resident/parking-spots-admin/parking-spots-admin.module').then(m => m.ParkingSpotsAdminModule) },
  { path: 'admin-anpr-residential-admin-parking-log', loadChildren: () => import('./admin_anpr_resident/parking-logs/parking-logs.module').then(m => m.ParkingLogsModule) },
  { path: 'admin-anpr-residential-admin-booking', loadChildren: () => import('./admin_anpr_resident/booking-admin/booking-admin.module').then(m => m.BookingAdminModule) },
  { path: 'admin-anpr-residential-admin-space-provider', loadChildren: () => import('./admin_anpr_resident/space-provider/space-provider.module').then(m => m.SpaceProviderModule) },
  { path: 'admin-anpr-residential-admin-registration', loadChildren: () => import('./admin_anpr_resident/newregistration/newregistration.module').then(m => m.NewregistrationModule) },


  /* ******************** ===============  RFID CORPORATE ADMIN  =============== ******************** */

  //Admin RFID Corporate
  { path: 'admin-rfid-corporate-admin-parking-manager', loadChildren: () => import('./admin_rfid_corporate/parking-manager/parking-manager.module').then(m => m.ParkingManagerModule) },
  { path: 'admin-rfid-corporate-admin-parking-spot', loadChildren: () => import('./admin_rfid_corporate/parking-spots-admin/parking-spots-admin.module').then(m => m.ParkingSpotsAdminModule) },
  { path: 'admin-rfid-corporate-admin-parking-log', loadChildren: () => import('./admin_rfid_corporate/parking-logs/parking-logs.module').then(m => m.ParkingLogsModule) },
  { path: 'admin-rfid-corporate-admin-booking', loadChildren: () => import('./admin_rfid_corporate/booking-admin/booking-admin.module').then(m => m.BookingAdminModule) },
  { path: 'admin-rfid-corporate-admin-space-provider', loadChildren: () => import('./admin_rfid_corporate/space-provider/space-provider.module').then(m => m.SpaceProviderModule) },
  { path: 'admin-rfid-corporate-admin-registration', loadChildren: () => import('./admin_rfid_corporate/newregistration/newregistration.module').then(m => m.NewregistrationModule) },
  { path: 'admin-rfid-corporate-admin-report', loadChildren: () => import('./admin_rfid_corporate/admin-report/admin-report.module').then(ar => ar.AdminReportModule) },

  /* ******************** ===============  MISC  =============== ******************** */

  { path: 'misc', loadChildren: () => import('./misc/vehicle-brands/vehicle-brands.module').then(vb => vb.VehicleBrandsModule) },
  { path: 'misc', loadChildren: () => import('./misc/vehicle-models/vehicle-models.module').then(vm => vm.VehicleModelsModule) },
  { path: 'misc', loadChildren: () => import('./misc/vehicle-types/vehicle-types.module').then(vt => vt.VehicleTypesModule) },
  { path: 'misc', loadChildren: () => import('./misc/wheeler-types/wheeler-types.module').then(wt => wt.WheelerTypesModule) },
  { path: 'misc', loadChildren: () => import('./misc/admin-users/admin-users.module').then(a => a.AdminUsersModule) },

  /* ******************** ===============  GSC Club  =============== ******************** */

  { path: 'gsc-club', loadChildren: () => import('./gsc_club/gsc-club-log/gsc-club-log.module').then(gsc => gsc.GscClubLogModule) },
  { path: 'gsc-club', loadChildren: () => import('./gsc_club/dashboard/dashboard.module').then(gsc => gsc.GscClubDashboardModule) },
  { path: 'gsc-club', loadChildren: () => import('./gsc_club/check-in-log/check-in-log.module').then(gsc => gsc.GscCheckInLogModule) },
  { path: 'gsc-club', loadChildren: () => import('./gsc_club/member/member.module').then(gsc => gsc.GscMemberModule) },

  /* ******************** ===============  Website  =============== ******************** */

  { path: 'website', loadChildren: () => import('./website/clients/clients.module').then(c => c.ClientsModule) },
  { path: 'website', loadChildren: () => import('./website/services/services.module').then(s => s.ServicesModule) },
  { path: 'website', loadChildren: () => import('./website/show-case/show-case.module').then(sc => sc.ShowCaseModule) },
  { path: 'website', loadChildren: () => import('./website/our-team/our-team.module').then(ot => ot.OurTeamModule) },
  { path: 'website', loadChildren: () => import('./website/contact/contact.module').then(c => c.ContactModule) },
  { path: 'website', loadChildren: () => import('./website/content/content.module').then(co => co.ContentModule) },
  { path: 'website', loadChildren: () => import('./website/blog/blog.module').then(b => b.BlogModule) },
  { path: 'website', loadChildren: () => import('./website/categories/categories.module').then(cat => cat.CategoriesModule) },
  { path: 'website', loadChildren: () => import('./website/testimonials/testimonials.module').then(t => t.TestimonialsModule) },
  { path: 'website', loadChildren: () => import('./website/our-installation/our-installation.module').then(oI => oI.OurInstallationModule) },

  /* ******************** =============== Vendor Oberoi International School  =============== ******************** */

  { path: 'student', loadChildren: () => import('./vendor_ois/student/student.module').then(vs => vs.StudentModule) },
  { path: 'student', loadChildren: () => import('./vendor_ois/student-vehicles/student-vehicles.module').then(sv => sv.StudentVehiclesModule) },
  { path: 'student', loadChildren: () => import('./vendor_ois/student-vehicle-logs/student-vehicle-logs.module').then(svl => svl.StudentVehicleLogsModule) },
  { path: 'student', loadChildren: () => import('./vendor_ois/gates/gates.module').then(g => g.GatesModule) },

  /* ******************** =============== Admin Oberoi International School  =============== ******************** */

  { path: 'admin-student', loadChildren: () => import('./admin_ois/student/student.module').then(ad => ad.StudentModule) },
  { path: 'admin-student', loadChildren: () => import('./admin_ois/student-vehicles/student-vehicles.module').then(sv => sv.StudentVehiclesModule) },
  { path: 'admin-student', loadChildren: () => import('./admin_ois/student-vehicle-logs/student-vehicle-logs.module').then(svl => svl.StudentVehicleLogsModule) },
  { path: 'admin-student', loadChildren: () => import('./admin_ois/gates/gates.module').then(ag => ag.GatesModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }