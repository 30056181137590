import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CommonServiceService } from "src/app/service/common-service.service";
import { ConstantsService } from "src/app/service/constant-service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-residential-view-spots-admin",
  templateUrl: "./residential-view-spots-admin.component.html",
  styleUrls: ["./residential-view-spots-admin.component.scss"],
})
export class ResidentialViewSpotsAdminANPRComponent implements OnInit {
  parking: any;
  public pageno: any;
  public currentPage: any;
  public p: any;
  public filterInput: any;
  public perpagedata: any;
  sort: any;
  public fetchExcel: any;
  fileName = "ExcelSheet.xlsx";

  constructor(
    public api: CommonServiceService,
    public constantService: ConstantsService,
    public toast: ToastrService,
    public route: Router
  ) {}

  ngOnInit() {
    let param = "?page_number=1&data_per_page=10";
    this.parkingSpot(param);
  }

  parkingSpot(param) {
    this.api
      .getRequest(this.constantService.PARKINGSPOTADMIN + param, "")
      .then((res: any) => {
        this.parking = res["data"];
        if (res["pagination"]) {
          this.pageno = res["pagination"].total_data_count;
          this.perpagedata = res["pagination"].data_per_page;
          this.currentPage = res["pagination"].page_number;
        } else {
          this.pageno = res["data"].lenght;
          this.perpagedata = 10;
          this.currentPage = 1;
        }
      })
      .catch((err: any) => {});
  }

  pagnation(p) {
    let param = "?page_number=" + p + "&data_per_page=10";
    this.parkingSpot(param);
  }

  filterTableColumn(colName, type) {
    if (!this.sort) {
      this.sort = !this.sort;
      this.parking = this.api.sortDataByCol(this.parking, colName, "des", type);
    } else {
      this.sort = !this.sort;
      this.parking = this.api.sortDataByCol(this.parking, colName, "asc", type);
    }
  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("example");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  updateActive(row) {
    var data = {
      active: row.active,
      id: row.id,
    };

    this.api
      .patchRequest(this.constantService.PARKINGSPOTADMIN + "/" + row.id, data)
      .then((res) => {
        this.toast.success("Updated Successfully");
      });
  }
}
