import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CommonServiceService } from "src/app/service/common-service.service";
import { ConstantsService } from "src/app/service/constant-service";

@Component({
  selector: "app-residential-edit-spots-admin",
  templateUrl: "./residential-edit-spots-admin.component.html",
  styleUrls: ["./residential-edit-spots-admin.component.scss"],
})
export class ResidentialEditSpotsAdminRFIDComponent implements OnInit {
  log: any;
  Ptype: any;
  public id: any;
  public spot: any;
  public address: any;
  public geoo: any;
  public coordi: any;
  public areaa: any;
  public landmarkk: any;
  public cityy: any;
  public ststea: any;
  public countryyy: any;
  public twowheeler: any;
  public twobuffer: any;
  public fourwheeler: any;
  public fourbuffer: any;
  public hmvv: any;
  public night: any;
  public cover: any;
  public list: any;
  public guard: any;
  public gate: any;
  public cctv: any;
  public cam: any;
  public charging: any;
  public hourtwoW: any;
  public hourfourW: any;
  public monthTwow: any;
  public monthfourw: any;
  public flat: any;
  public p: any;
  public pageno: any;
  public parking: any;
  public perpagedata: any;
  public currentPage: any;
  public sort: any;
  public filterInput: any;
  public total: any;
  public parkingSummary: any;
  public parkingPricing: any;
  public priceCategoryList: any;
  public parkingType: any;
  public selectedPricingCategory: any;
  public selectedParkingType: any;
  minhours: any;
  maxhours: any;
  price: any;

  constructor(
    public apiservice: CommonServiceService,
    public constantsService: ConstantsService,
    public toast: ToastrService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.getParkingSpotToEdit(this.id);
    this.initCall();
    this.getParkingType();
    this.getPricingCategory();
  }

  initCall() {
    let param = "?page_number=1&data_per_page=10";
    this.logs(param);
  }

  getParkingSpotToEdit(id) {
    var data = "?id=" + id;
    this.apiservice
      .getRequest(this.constantsService.PARKINGSPOTADMIN, data)
      .then((res) => {
        this.id = res["data"][0].id;
        this.spot = res["data"][0].parking_spot_name;
        this.address = res["data"][0].address;
        this.geoo = res["data"][0].geo_location;
        this.coordi = res["data"][0].coordinate;
        this.areaa = res["data"][0].area;
        this.landmarkk = res["data"][0].landmark;
        this.cityy = res["data"][0].city;
        this.ststea = res["data"][0].state;
        this.countryyy = res["data"][0].country;
        this.twowheeler = res["data"][0].total_number_two_wheeler;
        this.twobuffer = res["data"][0].two_wheeler_buffer;
        this.fourwheeler = res["data"][0].total_number_four_wheeler;
        this.fourbuffer = res["data"][0].four_wheeler_buffer;
        this.hmvv = res["data"][0].hmv;
        //this.parkingtypee = res['data'][0].parking_type;
        this.night = res["data"][0].amenity_overnight;
        this.cover = res["data"][0].amenity_covered;
        this.list = res["data"][0].amenity_lit;
        this.guard = res["data"][0].amenity_guarded;
        this.gate = res["data"][0].amenity_gated;
        this.cctv = res["data"][0].amenity_cctv;
        this.cam = res["data"][0].amenity_camera;
        this.charging = res["data"][0].amenity_charging_ev;
        this.hourtwoW = res["data"][0].price_per_hour_two_wheeler;
        this.hourfourW = res["data"][0].price_per_hour_four_wheeler;
        this.monthTwow = res["data"][0].price_per_month_two_wheeler;
        this.monthfourw = res["data"][0].price_per_month_four_wheeler;
        this.flat = res["data"][0].flat;
        //this.pricingcategory = res['data'][0].pricing_category;
        this.parkingPricing = res["data"][0].vehicle_pricings;
      });
  }

  getParkingType() {
    this.apiservice
      .getRequest(this.constantsService.PARKINGSPOTTYPE, [])
      .then((res) => {
        this.parkingType = res["data"];
      })
      .catch(() => {});
  }

  getPricingCategory() {
    this.apiservice
      .getRequest(this.constantsService.PRICINGCATEGORY, [])
      .then((res) => {
        this.priceCategoryList = res["data"];
      })
      .catch(() => {});
  }

  ParkingSpots(fieldType) {
    var data = { id: this.id };
    if (fieldType == "NAME") {
      data["parking_spot_name"] = this.spot;
    } else if (fieldType == "ADDRESS") {
      data["address"] = this.address;
    } else if (fieldType == "LOCATION") {
      data["geo_location"] = this.geoo;
    } else if (fieldType == "COORDINATE") {
      data["coordinate"] = this.coordi;
    } else if (fieldType == "AREA") {
      data["area"] = this.areaa;
    } else if (fieldType == "LANDMARK") {
      data["landmark"] = this.landmarkk;
    } else if (fieldType == "CITY") {
      data["city"] = this.cityy;
    } else if (fieldType == "STATE") {
      data["state"] = this.ststea;
    } else if (fieldType == "COUNTRY") {
      data["country"] = this.countryyy;
    } else if (fieldType == "TNTWOWHEELER") {
      data["total_number_two_wheeler"] = this.twowheeler;
    } else if (fieldType == "BUFFER") {
      data["two_wheeler_buffer"] = this.twobuffer;
    } else if (fieldType == "TNFOURWHEELER") {
      data["total_number_four_wheeler"] = this.fourwheeler;
    } else if (fieldType == "FOURBUFFER") {
      data["four_wheeler_buffer"] = this.fourbuffer;
    } else if (fieldType == "HMVV") {
      data["hmv"] = this.hmvv;
    } else if (fieldType == "OVERNIGHT") {
      data["amenity_overnight"] = this.night;
    } else if (fieldType == "COVERED") {
      data["amenity_covered"] = this.cover;
    } else if (fieldType == "LIST") {
      data["amenity_lit"] = this.list;
    } else if (fieldType == "GUARDED") {
      data["amenity_guarded"] = this.guard;
    } else if (fieldType == "GATED") {
      data["amenity_gated"] = this.gate;
    } else if (fieldType == "CCCTV") {
      data["amenity_cctv"] = this.cctv;
    } else if (fieldType == "CAMERA") {
      data["amenity_camera"] = this.cam;
    } else if (fieldType == "CHARGINGEV") {
      data["amenity_charging_ev"] = this.charging;
    } else if (fieldType == "PERHOURTWOW") {
      data["price_per_hour_two_wheeler"] = this.hourfourW;
    } else if (fieldType == "PERHOURFOURW") {
      data["price_per_hour_four_wheeler"] = this.hourtwoW;
    } else if (fieldType == "MONTHTWOO") {
      data["price_per_month_two_wheeler"] = this.monthTwow;
    } else if (fieldType == "MONTHFOURR") {
      data["price_per_month_four_wheeler"] = this.monthfourw;
    } else if (fieldType == "FLATTT") {
      data["flat"] = this.flat;
    }

    this.apiservice
      .patchRequest(
        this.constantsService.PARKINGSPOTADMIN + "/" + this.id,
        data
      )
      .then((res) => {
        this.toast.success("Updated Successfully");
      });
  }

  logs(param) {
    this.apiservice
      .getRequest(this.constantsService.ADMINLOG + param, "")
      .then((res: any) => {
        this.log = res["data"];
        if (res["pagination"]) {
          this.pageno = res["pagination"].total_data_count;
          this.perpagedata = res["pagination"].data_per_page;
          this.currentPage = res["pagination"].page_number;
        } else {
          this.pageno = res["data"].lenght;
          this.perpagedata = 10;
          this.currentPage = 1;
        }
      })
      .catch((err: any) => {});
  }

  pagnation(p) {
    let param = "?page_number=" + p + "&data_per_page=10";
    this.logs(param);
  }

  addParkingPricingSlot() {
    this.parkingPricing.push({
      vehicleType: "",
      pricingCategory: "",
      minHours: 0,
      maxHours: 0,
      parkingType: "",
      pricing: "",
    });
  }

  checkValue() {}

  filterBy() {
    this.apiservice
      .getRequest(
        this.constantsService.PRICE +
          "?pricing_category=" +
          this.selectedPricingCategory,
        []
      )
      .then((res) => {
        this.parkingPricing = [];
        this.parkingPricing = res["data"];
      })
      .catch(() => {});
  }
}
