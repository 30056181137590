import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "./core/services/auth.service";
import { CookieService } from "./core/services/cookie.service";
import { LocalCacheService } from "./service/cache/local-cache.service";
import { CommonServiceService } from "./service/common-service.service";

@Component({
  selector: "app-adminto",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public name: any;
  new: any;
  public title: any = "parkitIndexDB";
  auth_token: any;
  buttonName: any;

  constructor(
    public forage: LocalCacheService,
    public api: CommonServiceService,
    public router: Router,
    public authenticationService: AuthenticationService,
    public cookieService: CookieService
  ) {}

  handler(event) {
    this.name = event;
  }

  ngOnInit() {
    this.forage.setItem("Parkit", this.title);
    this.getdeviceID();
    this.api.checkVendorAdmin();
    this.loginCheck();
    this.isTimeExpired();
  }

  unloadNotification() {
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      e.returnValue = "";
    });
  }

  getdeviceID() {
    this.forage.getItem("auth_token").then((res) => {
      if (res) {
        this.api.headerUpdate$.next(true);
      } else {
        this.api.headerUpdate$.next(false);
      }
    });
  }

  loginCheck() {
    if (this.router.url == "/" || this.router.url == "admin-login") {
      this.forage.getItem("isVendor").then((res) => {
        if (res == true) {
          this.forage
            .getItem("isShowVendorNavMenu")
            .then((res) => {

              if (res["ois"]) {
                this.router.navigate(["/student/view-students"]);
              }

              if (res["club"]) {
                this.router.navigate(["/gsc-club/view-logs"]);
              }

              if (res["RFIDCorporate"]) {
                this.router.navigate(["/vendor-rfid-corporate-dashboard"]);
              }

              if (res["RFIDResidential"]) {
                this.router.navigate(["/residential-vendor-dashboard"]);
              }

              if (res["ANPRResidential"]) {
                this.router.navigate(["/vendor-anpr-resident-dashboard"]);
              }

              if (res["ANPRPublic"]) {
                this.router.navigate(["/vendor-dashboard"]);
              }
            })
            .catch(() => {});
        }
      });

      this.forage.getItem("isAdmin").then((res) => {
        if (res == true) {
          this.router.navigate(["/admin-dashboard"]);
        }
      });
    }
  }

  isTimeExpired() {
    try {
      const timer = JSON.parse(localStorage.getItem("timer"));
      if (timer && Date.now() > timer) {
        localStorage.removeItem("timer");
        this.cookieService.deleteCookie("currentUser");
        this.forage.clearCache();
        this.authenticationService.logout();
        this.api.isLoggedin = false;
        this.api.isAdmin = false;
        this.api.isVendor = false;
        this.router.navigate(["/"]);
      }
    } catch (error) {}
  }
}
